img {
  padding: 10px;
  border: black;
}

body {
  background-color: #111111;
}

a {
  color: black;
}

.card {
  margin-top: 1rem;
  background-color: #444444;
}

.card .card-body .card-text {
  text-align: center;
  color: #eeeeee;
}

.card-header {
  color: #eeeeee;
  background-color: #333333;
  text-align: center;
  font-size: 1rem;
}

.card-footer {
  text-align: center;
}

.badge-pill {
  padding-left: .6em;
  padding-right: .6em;
  font-size: .75rem;
  margin-right: .5rem;
  margin-top: 0.5rem;
}

.fab {
  font-size: 2rem;
}

.fasBig {
  font-size: 2rem;
}

.badgeLink {
  margin: .15rem;
  
}

.card .card-body .card-text .cardTextCentered {
  text-align: center;
}

.employmentCardHeader
{
  text-align: left;
  font-size: 0.75rem;
}

.card .card-body .employmentCardText
{
  text-align: left;
}

.employmentCard {
  background-color: #666666;
}

.employmentImage {
  text-align: right;
}

.card-image {
  text-align: center;
}